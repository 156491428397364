:root {
  --white: #ffffff;
  --gray-100: #e1e1e6;
  --gray-900: #121214;
  --cyan-500: #61dafb;
  --yellow-500: #eba417;
  --blue-900: #374f61;
  --red-100: #e51e24;
  --red-200: #bd242c;
  --red-300: #a32a2c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  background-color: var(--gray-100);
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: "Montserrat", sans-serif;
}

.appMain {
  flex-grow: 1;
  flex-shrink: 0;
}
